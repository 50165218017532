




































import { defineComponent, ref, Ref, PropType, watch } from '@vue/composition-api';

export default defineComponent({
  name: 'FcCheckFilter',
  props: {
    options: {
      type: Array as PropType<Option[]>,
      required: true,
      default: [],
    },
    label: {
      type: String,
      default: '絞り込み',
    },
    value: {
      type: Array as PropType<string[]>,
      required: true,
      default: [],
    },
    isDefaultSelectAll: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, context) {
    const selected: Ref<string[]> = ref(props.isDefaultSelectAll ? props.value : []);
    const isActiveDialog = ref(false);
    const isCheckedAll = ref(props.isDefaultSelectAll);
    // 全て選択のONOFFを切り替える
    const toggleCheckAll = () => {
      selected.value = isCheckedAll.value ? props.options.map((option) => option.value) : [];
    };

    // 各チェックボックスの値の変更に応じて、全て選択のONOFFを切り替える
    const refreshCheckAll = () => {
      if (selected.value.length === props.options.length) {
        isCheckedAll.value = true;
      } else {
        isCheckedAll.value = false;
      }
    };

    // 選択した項目を反映
    const setDisplay = () => {
      // チェックされた項目を表示するヘッダーの配列に追加、親コンポーネントに渡す。
      context.emit('input', selected.value);
      isActiveDialog.value = false;
    };

    // ダイアログをキャンセルした時：チェックボックスを変更前の状態に戻す
    const cancelDialog = () => {
      selected.value = props.value;
      refreshCheckAll();

      isActiveDialog.value = false;
    };

    watch(
      () => props.options,
      () => {
        if (props.isDefaultSelectAll && !selected.value.length) {
          selected.value = props.options.map((option) => option.value);
          isCheckedAll.value = true;
          // チェックされた項目を表示するヘッダーの配列に追加、親コンポーネントに渡す。
          context.emit('input', selected.value);
        }
      }
    );
    setDisplay();

    return {
      selected,
      isActiveDialog,
      isCheckedAll,
      setDisplay,
      toggleCheckAll,
      refreshCheckAll,
      cancelDialog,
    };
  },
});
