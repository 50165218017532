


































import { defineComponent, ref, Ref, PropType, watch, computed, ComputedRef } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import core from '@/admin/core';

export default defineComponent({
  name: 'FcTableHeaderFilter',
  props: {
    headers: {
      type: Array as PropType<DataTableHeader[]>,
      required: true,
      default: [],
    },
    defaultHeaders: {
      type: Array as PropType<DataTableHeader[]>,
      required: false,
      default: () => [],
    },
    isDisplayActions: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const actionsHeader: DataTableHeader = { text: '', value: 'actions', sortable: false, align: 'end' };
    const tableHeaders: ComputedRef<number[]> = computed(() =>
      props.headers
        .map((header, index) => {
          if (props.defaultHeaders.find((_header) => header.text === _header.text)) {
            return index;
          } else {
            return -1;
          }
        })
        .filter((index) => index !== -1)
    );
    const checkedIndex: Ref<number[]> = ref(tableHeaders.value);
    const displayHeaders: Ref<DataTableHeader[]> = ref([]);
    const isActiveDialog = ref(false);
    const isCheckedAll = ref(props.defaultHeaders.length ? props.defaultHeaders.length === props.headers.length : true);

    // 全て選択のONOFFを切り替える
    const toggleCheckAll = () => {
      checkedIndex.value = isCheckedAll.value ? [...Array(props.headers.length).keys()] : [];
    };

    // 各チェックボックスの値の変更に応じて、全て選択のONOFFを切り替える
    const refreshCheckAll = () => {
      if (checkedIndex.value.length === props.headers.length) {
        isCheckedAll.value = true;
      } else {
        isCheckedAll.value = false;
      }
    };

    // 選択したヘッダーを反映
    const setDisplayHeaders = () => {
      // 項目が1つもチェックされていないときは、全ての項目をチェックする
      if (!checkedIndex.value.length) {
        if (core.isPermitted) {
          checkedIndex.value = [...Array(props.headers.length).keys()];
          isCheckedAll.value = true;
        } else {
          checkedIndex.value = props.headers.reduce((acc, val, index) => {
            if (!val.value.includes('properties')) acc.push(index);
            return acc;
          }, [] as number[]);
        }
      }

      // チェックされた項目を表示するヘッダーの配列に追加、親コンポーネントに渡す。
      displayHeaders.value = props.isDisplayActions
        ? [...checkedIndex.value.sort((a, b) => a - b).map((index) => props.headers[index]), actionsHeader]
        : checkedIndex.value.sort((a, b) => a - b).map((index) => props.headers[index]);
      context.emit('input', displayHeaders.value);

      isActiveDialog.value = false;
    };

    // ダイアログをキャンセルした時：チェックボックスを変更前の状態に戻す
    const cancelDialog = () => {
      // actionsHeaderが含まれている場合は削除
      const currentHeaders = props.isDisplayActions ? displayHeaders.value.slice(0, -1) : displayHeaders.value;

      checkedIndex.value = currentHeaders.map((header) => {
        return props.headers.indexOf(header);
      });
      refreshCheckAll();

      isActiveDialog.value = false;
    };

    watch(
      () => props.headers,
      () => setDisplayHeaders()
    );

    setDisplayHeaders();

    return {
      checkedIndex,
      displayHeaders,
      tableHeaders,
      isActiveDialog,
      isCheckedAll,
      setDisplayHeaders,
      toggleCheckAll,
      refreshCheckAll,
      cancelDialog,
    };
  },
});
