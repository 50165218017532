






import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'FcRoleDeny',
  props: {
    type: {
      type: String,
      require: false,
      default: 'get',
    },
    text: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup(props) {
    const message = props.text
      ? props.text
      : `${props.type === 'get' ? '閲覧する' : props.type === 'create' ? '作成する' : ''}権限がありません`;
    return {
      message,
    };
  },
});
