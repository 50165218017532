


























































































import { defineComponent, ref, Ref, computed, ComputedRef } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import { useUser, User } from '@/admin/user';
import { usePayment } from '@/admin/payment';
import { getSeasons } from '@/admin/auth';
import { downloadCsv } from '@/admin/util';
import { useNotification } from '@/composition/notification';
import FcTableHeaderFilter from '@/components/FcTableHeaderFilter.vue';
import FcCheckFilter from '@/components/FcCheckFilter.vue';
import core from '@/admin/core';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Users',
  components: {
    FcTableHeaderFilter,
    FcCheckFilter,
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('users'));

    const { userProperties, getUsers, updateNote, removeNote } = useUser();
    const { getSubscriptionPlans } = usePayment();
    const notification = useNotification();
    // ユーザー一覧
    const headers: ComputedRef<DataTableHeader[]> = computed(() => {
      const properties = [
        { text: 'ユーザーID', value: 'userId' },
        { text: 'メールアドレス', value: 'email' },
        ...userProperties.value.map(({ key, title }) => ({ text: title, value: `properties.${key}` })),
        { text: '会員番号', value: 'userNumber' },
        { text: '登録日', value: 'createDate' },
        { text: '退会日', value: 'deleteDate' },
      ];
      if (process.env.VUE_APP_GROUP_TYPE === 'plan')
        properties.push({ text: '加入中のプラン', value: 'subscriptionNames' });
      if (process.env.VUE_APP_GROUP_TYPE === 'season')
        properties.push({ text: '加入中のシーズン', value: 'seasonNames' });
      properties.push({ text: '備考', value: 'note' });
      return properties;
    });
    const displayHeaders: Ref<DataTableHeader[]> = ref([]);
    const users: Ref<User[]> = ref([]);
    const isLoading = ref(true);
    const isDisplayDeletedItem = ref(false);

    // プランで絞り込み
    const selectedPlanValues: Ref<string[]> = ref([]);
    const plans: Ref<Option[]> = ref([]);
    getSubscriptionPlans().then((result) => {
      plans.value = result.map((plan) => ({
        text: plan.subscriptionPlanName,
        value: plan.subscriptionPlanId,
      }));
      if (process.env.VUE_APP_GROUP_TYPE === 'plan') {
        plans.value.push(
          {
            text: '無料プラン',
            value: 'freePlan',
          },
          {
            text: '途中離脱',
            value: 'uncompleted',
          },
          {
            text: '決済エラーによるプラン未登録状態',
            value: 'paymentError',
          }
        );
      }
    });
    // シーズンで絞り込み
    const selectedSeasonValues: Ref<string[]> = ref([]);
    const seasons: Ref<Option[]> = ref([]);
    getSeasons().then((result) => {
      seasons.value = result.map((season) => ({
        text: season.seasonName,
        value: season.seasonName,
      }));
    });

    // フリーワード検索
    const searchWord = ref('');

    // 表示ユーザー一覧
    const displayUsers: ComputedRef<User[]> = computed(() => {
      return (
        users.value
          // 退会ユーザー含めるか
          .filter((user) => isDisplayDeletedItem.value || !user.deleteDate)
          // 指定プランによる絞り込み
          .filter(
            (user) =>
              !selectedPlanValues.value.length ||
              user.displaySubscriptionPlans.some((plan) => selectedPlanValues.value.includes(plan.value))
          )
          // 指定シーズンによる絞り込み
          .filter(
            (user) =>
              !selectedSeasonValues.value.length ||
              selectedSeasonValues.value.find((selectedSeason) => user.seasonNames.includes(selectedSeason))
          )
          // フリーワードによる絞り込み
          .filter((user) => !searchWord.value || JSON.stringify(user).includes(searchWord.value))
      );
    });

    // テーブル高さ
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    // ダウンロードボタン
    const download = () => {
      // actionsの列は削除
      const csvHeaders = displayHeaders.value.slice(0, -1);
      downloadCsv(csvHeaders, displayUsers.value, 'users.csv');
    };

    // ユーザーノート
    const editNote = (user: User) => {
      const note = prompt('備考', user.note);
      if (typeof note !== 'string') return; // キャンセル
      if (note) updateNote(user, note);
      else removeNote(user);
    };

    getUsers()
      .then((result) => {
        users.value = result;
      })
      .catch((error) => {
        notification.error(error);
      })
      .finally(() => {
        isLoading.value = false;
      });

    return {
      pageTitle: 'ユーザー',
      myRoleSettings,
      headers,
      displayHeaders,
      isLoading,
      plans,
      selectedPlanValues,
      searchWord,
      displayUsers,
      tableHeight,
      download,
      editNote,
      isDisplayDeletedItem,
      isPermitted: core.isPermitted,
      selectedSeasonValues,
      seasons,
    };
  },
});
