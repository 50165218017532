import core from '@/admin/core';
import { Ref, ref } from '@vue/composition-api';
import { CampaignResponse } from '@/admin/campaign';

export const SUBSCRIPTION_PLAN_STATUS = {
  ACTIVE: 'active', // TODO: 削除予定
  DURING: 'during',
  BEFORE: 'before',
  AFTER: 'after',
  ARCHIVE: 'archive',
} as const;
export type SubscriptionPlanStatusType = typeof SUBSCRIPTION_PLAN_STATUS[keyof typeof SUBSCRIPTION_PLAN_STATUS];

export interface SubscriptionPlan {
  subscriptionPlanId: string;
  subscriptionPlanName: string;
  subscriptionPlanStatus: SubscriptionPlanStatusType;
  subscriptionProductId: string;
  priceId: string;
  price: number;
  interval: 'day' | 'month' | 'year';
  intervalCount: number;
  description: string;
  counterPriorities: string[];
  order: number;
  isArchive: boolean;
  scheduledArchiveDate?: number;
  archiveDate?: number;
  initialSettings?: {
    priceId: string;
    price: number;
  };
  accessStartDate?: number;
  accessEndDate?: number;
  subscriptionCampaigns: CampaignResponse[];
  isRequiredShippingAddress: boolean;
  maxSubscribersCount?: number;
  isEnrollmentStopped: boolean;
  currentSubscribersCount?: number;
  reservedSubscribersCount?: number;
}
export interface SubscriptionProduct {
  subscriptionProductId: string;
  subscriptionProductName: string;
}

export interface SubscriptionPlanProps {
  subscriptionPlanId: string;
  subscriptionPlanName: string;
  subscriptionProductId: string;
  price: number;
  description: string;
  counterPriorities: string[];
  order?: number;
  initialPrice?: number | string;
  interval: 'day' | 'month' | 'year';
  intervalCount: number;
  isArchive?: boolean;
  accessStartDate: number;
  accessEndDate?: number;
  isRequiredShippingAddress: boolean;
  maxSubscribersCount?: number;
  isEnrollmentStopped?: boolean; // NOTE: 取得の際はundefinedであることはないが、加入者数上限フラグがOFFの場合はこの値をリクエストに含めないため、undefinedを許容する
}
interface AddProps {
  endUsername: string;
  subscriptionPlanId: string;
}

interface SubscriptionPlanDetail extends SubscriptionPlan {
  isNoSubscriptions: boolean;
}

const subscriptionsPlans: Ref<SubscriptionPlan[]> = ref([]); // サブスクリプションプラン一覧取得APIから取得したサブスクリプションプラン一覧を保持する
/**
 * サブスクリプションプラン一覧を取得する
 *
 * @remarks
 * サブスクリプションプラン一覧取得APIからデータを取得し、
 * 取得したデータは昇順に並べ替えを行う。
 *
 * @returns サブスクリプションプラン一覧
 */
const _getSubscriptionPlans = async () => {
  if (process.env.VUE_APP_GROUP_TYPE !== 'plan') return [];
  try {
    const result = await core.httpClient.get('/admin/public/subscription/plans');
    return result.data as SubscriptionPlan[];
  } catch (e) {
    return [];
  }
};

const subscriptionProducts: Ref<SubscriptionProduct[]> = ref([]);
const _getSubscriptionProducts = async () => {
  if (process.env.VUE_APP_GROUP_TYPE !== 'plan') return [];
  try {
    const result = await core.httpClient.get('/admin/public/subscription/products');
    return (result.data as SubscriptionProduct[]).sort((a, b) =>
      a.subscriptionProductName > b.subscriptionProductName ? 1 : -1
    );
  } catch (e) {
    return [] as SubscriptionProduct[];
  }
};

/**
 * サブスクリプションプラン一覧を返却する
 *
 * @returns サブスクリプションプラン一覧
 */
export const getSubscriptionPlans = async (refresh?: boolean) => {
  if (!subscriptionsPlans.value.length || refresh) subscriptionsPlans.value = await _getSubscriptionPlans();
  return subscriptionsPlans.value;
};
export const getSubscriptionPlan = async (planId?: string) => {
  const subscriptionPlan = (await core.httpClient.get(`/admin/public/subscription/plans/${planId}`)).data;
  return subscriptionPlan as SubscriptionPlanDetail;
};
export const saveSubscriptionPlan = async (props: SubscriptionPlanProps, planId?: string) => {
  if (planId) {
    await core.httpClient.put(`/admin/public/subscription/plans/${planId}`, props);
  } else {
    await core.httpClient.post('/admin/public/subscription/plans', props);
  }
  subscriptionsPlans.value = await _getSubscriptionPlans();
};
export const deleteSubscriptionPlan = async (planId: string) => {
  await core.httpClient.delete(`/admin/public/subscription/plans/${planId}`);
  const archivePlan = subscriptionsPlans.value.find((plan) => planId === plan.subscriptionPlanId);
  if (archivePlan) {
    const index = subscriptionsPlans.value.indexOf(archivePlan);
    subscriptionsPlans.value[index].isArchive = true;
  }
};
export const createScheduleForArchiveSubscriptionPlan = async (subscriptionPlanId: string, archiveDate: number) => {
  await core.httpClient.post(`/admin/public/subscription/plans/${subscriptionPlanId}/archiveSchedule`, { archiveDate });
};
export const deleteScheduleForArchiveSubscriptionPlan = async (subscriptionPlanId: string) => {
  await core.httpClient.delete(`/admin/public/subscription/plans/${subscriptionPlanId}/archiveSchedule`);
};
export const getSubscriptionProducts = async () => {
  if (!subscriptionProducts.value.length) subscriptionProducts.value = await _getSubscriptionProducts();
  return subscriptionProducts.value;
};
export const saveSubscriptionProduct = async (props: { subscriptionProductName: string }, productId?: string) => {
  if (productId) {
    await core.httpClient.put(`/admin/public/subscription/products/${productId}`, props);
  } else {
    await core.httpClient.post('/admin/public/subscription/products', props);
  }
  subscriptionProducts.value = await _getSubscriptionProducts();
};

export const addSubscriptionPlan = async (props: AddProps) => {
  await core.httpClient.post('/admin/public/subscription', props);
};

export const usePayment = () => {
  return {
    getSubscriptionPlans,
    getSubscriptionProducts,
    getSubscriptionPlan,
    addSubscriptionPlan,
    saveSubscriptionPlan,
    saveSubscriptionProduct,
    deleteSubscriptionPlan,
    createScheduleForArchiveSubscriptionPlan,
    deleteScheduleForArchiveSubscriptionPlan,
  };
};
