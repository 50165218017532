import { Ref, ref } from '@vue/composition-api';
import core from '@/admin/core';
import { usePayment } from '@/admin/payment';
import { DisplayDate, getElapsedDate } from '@/admin/util';
import { getSeasons } from '@/admin/auth';
import myAttributes from '@/composition/myAttributes';

interface GetUserResponse {
  username: string;
  email: string;
  properties: {
    [key: string]: string;
  };
  userNumber: number;
  createDate: number;
  deleteDate?: number;
  isDeleted: boolean;
  subscriptionPlanIds: string[];
  seasonIds: number[];
  isFailedLatestSubscriptionPayment: boolean;
  isUncompletedSignup?: boolean;
  favoriteItemSettings?: {
    favoriteItemId: string;
    favoriteStartDate: number;
    favoritePeriod: number;
    isExpiredFavoriteItem: boolean;
    favoriteItemStatus?: {
      favoriteItemStatusName: string;
      favoriteItemStatusId: string;
    };
  };
}

interface GetUserNotesResponse {
  noteId: string;
  body: string;
  createDate: string;
  createUser: string;
  updateDate: string;
  updateUser: string;
}

interface GetUserPropertiesResponse {
  entrySchema: {
    [key: string]: {
      title: string;
      type: string;
      uiType: string;
      required: boolean;
      order: number;
    };
  };
}

interface UserProperty {
  key: string;
  title: string;
  type: string;
  uiType: string;
  required: boolean;
  order: number;
}

interface UserGroupResponse {
  groupName: string;
  description: string;
}

export interface GetLeaveReasonsResponse {
  username: string;
  datetime: number;
  reasons: string[];
  message: string;
}

export class User {
  userId: string;
  email: string;
  properties: {
    [key: string]: string;
  };
  userNumber: number;
  createDate: string;
  deleteDate: string;
  subscriptionPlanIds: string[];
  displaySubscriptionPlans: Option[];
  subscriptionNames: string[];
  seasonNames: string[];
  note: string;
  isUncompletedSignup: boolean;
  favoriteItemId: string;
  favoriteStartDate: string;
  favoritePeriod: string;
  favoriteItemStatusName: string;
  favoriteItemStatusId: string;
  isExpiredFavoriteItem: boolean;

  constructor(
    props: GetUserResponse,
    displaySubscriptionPlans: Option[],
    seasonNames: string[],
    note?: GetUserNotesResponse
  ) {
    this.userId = props.username;
    this.email = props.email;
    this.properties = props.properties;
    this.userNumber = props.userNumber;
    this.createDate = new DisplayDate(props.createDate).format('YYYY-MM-DD');
    this.deleteDate = props.deleteDate ? new DisplayDate(props.deleteDate).format('YYYY-MM-DD') : '';
    this.subscriptionPlanIds = props.subscriptionPlanIds;
    this.displaySubscriptionPlans = displaySubscriptionPlans;
    this.subscriptionNames = displaySubscriptionPlans.map((plan) => plan.text);
    this.seasonNames = seasonNames;
    this.note = note ? note.body : '';
    this.isUncompletedSignup = !!props.isUncompletedSignup;
    this.favoriteItemId = props.favoriteItemSettings?.favoriteItemId || '';
    this.favoriteStartDate = new DisplayDate(props.favoriteItemSettings?.favoriteStartDate).format('YYYY-MM-DD');
    this.favoritePeriod =
      props.favoriteItemSettings?.favoritePeriod !== undefined
        ? getElapsedDate(props.favoriteItemSettings?.favoritePeriod)
        : '';
    this.favoriteItemStatusName = props.favoriteItemSettings?.favoriteItemStatus?.favoriteItemStatusName || '';
    this.favoriteItemStatusId = props.favoriteItemSettings?.favoriteItemStatus?.favoriteItemStatusId || '';
    this.isExpiredFavoriteItem = !!props.favoriteItemSettings?.isExpiredFavoriteItem;
  }
}

let getUsersPromise: Promise<User[]>;
let getTestUsersPromise: Promise<User[]>;
const userProperties: Ref<UserProperty[]> = ref([]);

const _getUserProperties = async (): Promise<GetUserPropertiesResponse | null> => {
  if (myAttributes.myRequestPermissions?.forms) {
    const result = await core.httpClient.get('/admin/public/forms/_userProperties');
    return result.data as GetUserPropertiesResponse;
  } else return null;
};

const _getUserNotes = async (): Promise<GetUserNotesResponse[]> => {
  if (!myAttributes.myRequestPermissions?.users && !myAttributes.myRequestPermissions?.usersWithoutPersonalInfos) {
    return [];
  }

  const result = await core.httpClient.get('/admin/public/userNotes');
  return result.data;
};

const _getUsersWithPersonalInfos = async (): Promise<GetUserResponse[]> => {
  let nextToken: string | undefined = undefined;
  let usersWithPersonalInfos: GetUserResponse[] = [];
  do {
    // TODO: paging=true削除
    const result = await core.httpClient.get(
      `/admin/restrict/users?paging=true${nextToken ? `&nextToken=${nextToken}` : ''}`
    );
    const response = result.data as { users: GetUserResponse[]; nextToken?: string };
    usersWithPersonalInfos = usersWithPersonalInfos.concat(response.users);
    nextToken = response.nextToken;
  } while (nextToken);
  return usersWithPersonalInfos;
};

const _getUsersWithoutPersonalInfos = async (): Promise<GetUserResponse[]> => {
  let nextToken: string | undefined = undefined;
  let usersWithPersonalInfos: GetUserResponse[] = [];
  do {
    // TODO: paging=true削除
    const result = await core.httpClient.get(
      `/admin/public/users?paging=true${nextToken ? `&nextToken=${nextToken}` : ''}`
    );
    const response = result.data as { users: GetUserResponse[]; nextToken?: string };
    usersWithPersonalInfos = usersWithPersonalInfos.concat(response.users);
    nextToken = response.nextToken;
  } while (nextToken);
  return usersWithPersonalInfos;
};
const _getUsersData = async (): Promise<GetUserResponse[]> => {
  // 権限がなければ空配列を返す
  if (!myAttributes.myRequestPermissions?.users && !myAttributes.myRequestPermissions?.usersWithoutPersonalInfos) {
    return [];
  }

  // アクセス時のIPが許可されている場合、権限に応じてユーザー情報を取得
  if (core.isPermitted && myAttributes.myRequestPermissions?.users) {
    return await _getUsersWithPersonalInfos();
  }
  if (myAttributes.myRequestPermissions?.usersWithoutPersonalInfos) {
    return await _getUsersWithoutPersonalInfos();
  }

  return [];
};

const _getUsers = async () => {
  const { getSubscriptionPlans } = usePayment();

  const [getUsersResponse, getUserNotesResponse, getUserPropertiesResponse, plans, seasons] = await Promise.all([
    _getUsersData(),
    _getUserNotes(),
    _getUserProperties(),
    getSubscriptionPlans(),
    getSeasons(),
  ]);

  // ユーザー属性を格納
  const userPropertiesResponse = getUserPropertiesResponse;
  if (userPropertiesResponse) {
    const properties = Object.keys(userPropertiesResponse.entrySchema).map((key) => {
      const property = userPropertiesResponse.entrySchema[key];
      return { key, ...property };
    });
    properties.sort((a, b) => (a.order < b.order ? -1 : 1));
    userProperties.value = properties;
  }

  const users = getUsersResponse;
  const notes = getUserNotesResponse;

  return users
    .filter((user) => {
      // 暫定対応：個人情報削除済みのユーザーは非表示
      return !user.properties || Object.keys(user.properties).length > 0;
    })
    .map((user) => {
      const displaySubscriptionPlans: Option[] = !user.subscriptionPlanIds.length
        ? user.isUncompletedSignup
          ? [
              {
                text: '途中離脱',
                value: 'uncompleted',
              },
            ]
          : user.isFailedLatestSubscriptionPayment
          ? [
              {
                text: '決済エラーによるプラン未登録状態',
                value: 'paymentError',
              },
            ]
          : [
              {
                text: '無料プラン',
                value: 'freePlan',
              },
            ]
        : user.subscriptionPlanIds?.map((subscriptionPlanId) => {
            const subscriptionPlan = plans.find((plan) => plan.subscriptionPlanId === subscriptionPlanId);
            return {
              text: subscriptionPlan?.subscriptionPlanName || '',
              value: subscriptionPlan?.subscriptionPlanId || '',
            };
          });
      const seasonNames =
        user.seasonIds?.map((seasonId) => {
          const season = seasons.find((item) => item.seasonId === seasonId);
          return season?.seasonName || '';
        }) || [];
      const note = notes.find((note) => note.noteId.replace(/^user_/, '') === user.username);
      return new User(user, displaySubscriptionPlans, seasonNames, note);
    });
};

const getUsers = () => {
  if (!getUsersPromise) getUsersPromise = _getUsers();
  return getUsersPromise;
};

const getGroupUsers = async (groupName: string) => {
  if (!myAttributes.myRequestPermissions?.users) return [];

  const result =
    core.isPermitted && myAttributes.myRequestPermissions?.users
      ? await core.httpClient.get(`/admin/restrict/users/${groupName}`)
      : await core.httpClient.get(`/admin/public/users/${groupName}`);
  return result.data as GetUserResponse[];
};

const _getTestUsers = async () => {
  const { getSubscriptionPlans } = usePayment();

  const [getUsersResponse, getUserNotesResponse, getUserPropertiesResponse, plans, seasons] = await Promise.all([
    getGroupUsers('testUser'),
    _getUserNotes(),
    _getUserProperties(),
    getSubscriptionPlans(),
    getSeasons(),
  ]);

  // ユーザー属性を格納
  const userPropertiesResponse = getUserPropertiesResponse as GetUserPropertiesResponse;
  if (userPropertiesResponse) {
    const properties = Object.keys(userPropertiesResponse.entrySchema).map((key) => {
      const property = userPropertiesResponse.entrySchema[key];
      return { key, ...property };
    });
    properties.sort((a, b) => (a.order < b.order ? -1 : 1));
    userProperties.value = properties;
  }

  const users = getUsersResponse as GetUserResponse[];
  const notes = getUserNotesResponse;

  return users.map((user) => {
    const subscriptionPlans: Option[] = user.subscriptionPlanIds?.map((subscriptionPlanId) => {
      const subscriptionPlan = plans.find((plan) => plan.subscriptionPlanId === subscriptionPlanId);
      return {
        text: subscriptionPlan?.subscriptionPlanName || '',
        value: subscriptionPlan?.subscriptionPlanId || '',
      };
    });
    const seasonNames =
      user.seasonIds?.map((seasonId) => {
        const season = seasons.find((item) => item.seasonId === seasonId);
        return season?.seasonName || '';
      }) || [];
    const note = notes.find((note) => note.noteId.replace(/^user_/, '') === user.username);
    return new User(user, subscriptionPlans, seasonNames, note);
  });
};
const getTestUsers = () => {
  if (!getTestUsersPromise) getTestUsersPromise = _getTestUsers();
  return getTestUsersPromise;
};

/**
 * 保持しているテストユーザー一覧情報を更新する
 *
 * @param users - テストユーザー一覧
 */
const updateTestUsers = (users: User[]) => {
  getTestUsersPromise = Promise.resolve(users);
};

// testユーザーを含む
const getAllUsers = async () => {
  const [users, testUsers] = await Promise.all([getUsers(), getTestUsers()]);
  return [...users, ...testUsers] as User[];
};

const updateNote = async (user: User, note: string) => {
  if (user.note === note) return;
  user.note = note;
  await core.httpClient.post('/admin/public/userNotes', {
    targetUsername: user.userId,
    body: note,
  });
};

const removeNote = async (user: User) => {
  if (!user.note) return;
  user.note = '';
  await core.httpClient.delete(`/admin/public/userNotes?userId=${user.userId}`);
};

const getUserGroups = async () => {
  const result = await core.httpClient.get('/admin/public/userGroups');
  return result.data as UserGroupResponse[];
};

const createTestUser = async (email: string, groupNames: string[]) => {
  const result = await core.httpClient.post('/admin/public/users', { email, groupNames });
  return result.data as { password: string };
};

const updateUserGroups = async (userId: string, groupNames: string[]) => {
  await core.httpClient.put('/admin/public/users/userGroups', { testUsername: userId, groupNames: groupNames });
};

export const useUser = () => {
  return {
    userProperties,
    getUsers,
    updateNote,
    removeNote,
    getUserGroups,
    createTestUser,
    getTestUsers,
    updateTestUsers,
    getAllUsers,
    updateUserGroups,
  };
};

export const getLeaveReasons = async () => {
  if (myAttributes.myRequestPermissions?.leaveReasons) {
    const result = await core.httpClient.get('/admin/public/leaveReasons');
    return result.data as GetLeaveReasonsResponse[];
  } else return [] as GetLeaveReasonsResponse[];
};
